import React from 'react'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import * as styles from '../templates/page.module.css'

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo title="Sidan kan inte hittas" />
        <Hero title="Sidan kan inte hittas" />
        <div className={styles.container}>
          <div className={styles.article}>
            <p>Sidan du letar efter finns tyvärr inte. Letar du kanske efter <a href="/referenser">referenser</a> eller <a href="/kontakt">kontaktuppgifter?</a></p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default NotFoundPage
